import { Button } from '@mui/material'
import { Link, routes } from '@redwoodjs/router'
import { Toaster } from '@redwoodjs/web/toast'

type EntryLayoutProps = {
  children: React.ReactNode
}

const EntriesLayout = ({ children }: EntryLayoutProps) => {
  return (
    <div className="rw-scaffold pt-3 bg-gradient-to-br from-white to-indigo-100 min-h-screen">
      <Toaster />
      <header className="flex items-center justify-between max-w-[1200px] mx-6 xl:mx-auto py-3">
        <h1 className="rw-heading rw-heading-primary">
          <Link to={routes.entries()} className="rw-link">
            Entries
          </Link>
        </h1>
        <Link to={routes.newEntry()}>
          <Button color="secondary" variant="contained">
            New Entry
          </Button>
        </Link>
      </header>
      <main>{children}</main>
    </div>
  )
}
export default EntriesLayout
