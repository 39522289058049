import { Logout } from '@mui/icons-material'
import { Avatar, ListItemIcon, Menu, MenuItem } from '@mui/material'
import { useAuth } from '@redwoodjs/auth'
import { Link, NavLink, routes } from '@redwoodjs/router'
import React, { useState } from 'react'

const AppHeader = () => {
  const { currentUser, logOut } = useAuth()
  const [anchorEl, setAnchorEl] = useState(null);
  const open = !!anchorEl

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  }
  const handleSignOut = () => {
    logOut()
  };

  return (
    <div
      className={[
        'border-b',
        'border-gray-300',
        'flex',
        'justify-between',
        'relative',
        'shadow-sm',
        'z-9',
      ].join(' ')}
    >
      <Link
        className={[
          'text-transparent',
          'text-xl',
          'bg-clip-text',
          'bg-gradient-to-br',
          'font-bold',
          'from-blue-700',
          'px-4',
          'py-2',
          'to-pink-500',
          'w-48',
        ].join(' ')}
        to={routes.home()}
      >
        LM Redwood
      </Link>
      <nav className="flex font-semibold gap-5 items-center text-gray-600 text-sm">
        <NavLink
          activeClassName="border-indigo-300 text-indigo-600"
          className="hover:text-indigo-600 border-b border-transparent pb-0.25"
          to={routes.entries()}
        >
          Entries
        </NavLink>
        <NavLink
          activeClassName="border-indigo-300 text-indigo-600"
          className="border-b border-transparent hover:text-indigo-600 pb-0.25"
          to="/a"
        >
          Schemas
        </NavLink>
      </nav>
      <div className={'flex items-center justify-end px-4 w-48'}>
        {
          currentUser?.user_metadata?.avatar_url &&
          <div className="flex">
            <button
              onClick={handleClick}
            >
              <img
                className="hover:ring-blue-400 hover:ring object-cover rounded-full h-9 w-9"
                src={currentUser?.user_metadata?.avatar_url}
              />
            </button>
            <Menu
              id="basic-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              elevation={3}
            >
              <MenuItem
                onClick={handleSignOut}
                sx={{fontSize: "14px"}}
              >
                <ListItemIcon>
                  <Logout fontSize="small" />
                </ListItemIcon>
                Sign Out
              </MenuItem>
            </Menu>
          </div>
        }
      </div>
    </div>
  )
}

export default AppHeader
