// In this file, all Page components from 'src/pages` are auto-imported. Nested
// directories are supported, and should be uppercase. Each subdirectory will be
// prepended onto the component name.
//
// Examples:
//
// 'src/pages/HomePage/HomePage.js'         -> HomePage
// 'src/pages/Admin/BooksPage/BooksPage.js' -> AdminBooksPage

import { Set, Router, Route, Private } from '@redwoodjs/router'
import EntriesLayout from 'src/layouts/EntriesLayout'
import AppLayout from './layouts/EntriesLayout/AppLayout'

const Routes = () => {
  return (
    <Router>
      <Private unauthenticated="login">
        <Set wrap={AppLayout}>
          <Set wrap={EntriesLayout}>
            <Route path="/" page={EntryEntriesPage} name="home" />
            <Route path="/entries/new" page={EntryNewEntryPage} name="newEntry" />
            <Route path="/entries/{id:Int}/edit" page={EntryEditEntryPage} name="editEntry" />
            <Route path="/entries/{id:Int}" page={EntryEntryPage} name="entry" />
            <Route path="/entries" page={EntryEntriesPage} name="entries" />
          </Set>
        </Set>
      </Private>
      <Route path="/login" page={LoginPage} name="login" />
      <Route notfound page={NotFoundPage} />
    </Router>
  )
}

export default Routes
