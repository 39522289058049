import { AuthProvider } from '@redwoodjs/auth'
import { createClient } from '@supabase/supabase-js'
import { FatalErrorBoundary, RedwoodProvider } from '@redwoodjs/web'
import { RedwoodApolloProvider } from '@redwoodjs/web/apollo'
import '@fontsource/inter/variable.css'

import FatalErrorPage from 'src/pages/FatalErrorPage'
import Routes from 'src/Routes'

import './index.css'
import { createTheme, ThemeProvider } from '@mui/material'
import { Shadows } from '@mui/material/styles/shadows'

const supabaseClient = createClient(
  process.env.SUPABASE_URL,
  process.env.SUPABASE_KEY
)

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          textTransform: 'none',
        },
        containedPrimary: {},
        containedSecondary: {
          color: '#435079',
        },
        outlinedSecondary: {
          color: '#343440',
        },
      },
    },
    // Name of the component
    MuiButtonBase: {
      defaultProps: {
        // The props to change the default for.
      },
    },
  },
  palette: {
    primary: {
      main: '#0345fc',
    },
    secondary: {
      main: '#dadaf4',
    },
  },
  shadows: [
    'none',
    '0.5px 1px 3px hsla(227, 60%, 7%, 0.1)',
    '0.5px 1px 3px hsla(227, 60%, 7%, 0.1)',
    '0.5px 1px 4px hsla(227, 60%, 8%, 0.12)',
    ...Array(22).fill('none'),
  ] as Shadows,
  typography: {
    fontFamily: [
      'InterVariable',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
})

const App = () => (
  <ThemeProvider theme={theme}>
    <FatalErrorBoundary page={FatalErrorPage}>
      <RedwoodProvider titleTemplate="%PageTitle | %AppTitle">
        <AuthProvider client={supabaseClient} type="supabase">
          <RedwoodApolloProvider>
            <Routes />
          </RedwoodApolloProvider>
        </AuthProvider>
      </RedwoodProvider>
    </FatalErrorBoundary>
  </ThemeProvider>
)

export default App
