import AppHeader from 'src/components/AppHeader/AppHeader'

const AppLayout = ({ children }) => {
  return (
    <div>
      <AppHeader />
      <main>{children}</main>
    </div>
  )
}

export default AppLayout
